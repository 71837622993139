const coinIcon = new URL('../assets/coin.png', import.meta.url).toString()

export type Balance = {
    usdc: number
    point: number
    airdrop: number
    airdropExpiredAt?: string
    totalInGame: number
}

export type Currency = {
    name: string
    img: string
    decimals?: number
    gradient?: string
    disabled?: boolean
    blockchain?: string
    deposit?: boolean
    deposit_type?: number
}

export const Br1TokenCurrency: Currency = {
    name: 'BR1TOKEN',
    img: coinIcon,
    decimals: 2,
}

export type Validator = (value: any) => boolean

export type WithdrawStrategy = {
    fee: number
    min: number
}

type WithdrawRequestStatus = {
    status: string
    created_at: string
    created_by: string
}

export type WithdrawRequest = {
    uuid: string
    wallet: string
    status: string
    created_by: string
    created_at: string
    updated_at: string
    currency: string
    amount: number
    token_amount: number
    status_history: WithdrawRequestStatus[]
    blockchain_tx: string
    blockchain_tx_url: string
    user_id: number
    payout_error?: string
    approve_comment?: string
}

export type WithdrawRequestsParams = {
    page?: number
    page_size?: number
    filters?: {
        status?: string
        username?: string
    }
}

export type WithdrawUserRequestsParams = {
    page?: number
    filters?: {
        status?: 'all' | 'submitted' | 'processing' | 'completed'
    }
}

export type WithdrawRequestListResponse = {
    count: number
    page_size: number
    page: number
    pages: number
    data: WithdrawRequest[]
}

export type WithdrawRequestSummary = {
    all: number
    created: number
    approved: number
    processing: number
    finalized: number
}

export type PayoutWalletBalance = {
    pubkey: string
    currency: string
    amount: number
    updated_at: string
}

export type EarningFeeStatistic = {
    total: number
    hours_24: number
    days_7: number
    days_30: number
    today: number
    week: number
    month: number
}
