import { useQuery } from '@tanstack/react-query'
import { useUser } from '~/features/auth/api/useUser'
import { JsonRpcError } from '~/types'
import { WithdrawRequestListResponse, WithdrawRequestsParams } from '../types'

export const useWithdrawRequests = ({ page = 1, page_size = 10, filters }: WithdrawRequestsParams) => {
    const { data: user } = useUser()
    return useQuery<WithdrawRequestListResponse, JsonRpcError>({
        queryKey: ['withdraw_requests', page, filters, page_size],
        queryFn: async () => {
            const res = await fetch('/rpc', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    jsonrpc: '2.0',
                    id: 1,
                    method: 'bank.withdraw_request.all',
                    params: {
                        page,
                        page_size,
                        filters,
                    },
                }),
            })
            const data = await res.json()
            if (data.error) {
                throw data
            } else {
                return data.result
            }
        },
        retry: false,
        keepPreviousData: true,
        enabled: user.is_auth && (user.is_marketing || user.is_executive),
    })
}
